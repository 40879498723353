import React, { useRef, useState, useEffect } from 'react';

import ReCaptcha from '@pittica/gatsby-plugin-recaptcha';
import SimpleReactValidator from 'simple-react-validator';
import { useDropzone } from 'react-dropzone'

import { FormattedMessage, useIntl } from '../../../plugins/publicis-gatsby-plugin-i18n';
import { Button } from '../../Molecules/Button/Button';
import Input from '../../Molecules/Input/Input';
import Textarea from '../../Molecules/Textarea/Textarea';

import './ContactForm.scss';
import { LoaderIcon } from '../../Atoms/Icons/Icons';
const axios = require('axios').default;
const ContactForm = ({ formName, langCode, globalConfig }) => {
  const intl = useIntl();
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const [subject, setSubject] = useState('');
  const [lastname, setLastname] = useState('');
  const [firstname, setFirstname] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [files, setFiles] = useState([]);
  const [filesRejected, setFileRejected] = useState("");
  const [uploadSizEexceeded, setUploadSizEexceeded] = useState(false);
  const [sendingData, setSendingData] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const webFormSessionUrl = `${process.env.GATSBY_DRUPAL_BASE_URL}session/token`;
  const webFormSubmitUrl = `${process.env.GATSBY_DRUPAL_BASE_URL}${langCode}/webform_rest/submit?_format=json`;
  const webFormSubmitFileUrl = `${process.env.GATSBY_DRUPAL_BASE_URL}webform_rest/${formName}/upload/piece_jointe?_format=json`;
  const fileValidator = (file) => {
    if (file.size > 10000000) {
      return {
        code: "file-too-large",
        message: intl.formatMessage({ id: 'contact.form.field.upload.file_too_large' }) || 'file is larger than 10MB',
      };
    }
    return null;
  }
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    //maxSize: 20000000,
    validator: fileValidator,
    maxFiles: 3,
    onDrop: (acceptedFiles, fileRejections) => {
      const newFiles = acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      }));
      let old_new_files = [...files, ...newFiles]
      old_new_files = old_new_files.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.name === value.name && t.size === value.size
        ))
      )
      console.log(fileRejections);
      setFileRejected(fileRejections);
      setFiles(old_new_files);
    },
    accept: {
      'image/png': ['.png'],
      'image/gif': ['.gif'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'application/pdf': ['.pdf'],
      'text/csv': ['.csv'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'application/vnd.ms-powerpoint': ['.ppt'],
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
      'application/rtf': ['.rtf'],
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/vnd.oasis.opendocument.text': ['.odt'],
      'application/vnd.ms-powerpoint': ['.pps'],
      'application/vnd.openxmlformats-officedocument.presentationml.slideshow': ['.ppsx'],
      'text/plain': ['.txt']
    }
  })
  const deleteUploadedFile = (file_index) => {
    const newFiles = [...files]
    newFiles.splice(file_index, 1)
    setFiles(newFiles)
  }

  const deleteUploadedRejectedFile = (file_index) => {
    const newFiles = [...filesRejected]
    newFiles.splice(file_index, 1)
    setFileRejected(newFiles)
  }

  useEffect(() => {
    console.log(files);
    let total_size = 0;
    files?.map(file => {
      total_size += file.size;
    });
    total_size = total_size != 0 ? total_size / 1024 / 1024 : total_size;
    if (total_size > 20) {
      setUploadSizEexceeded(true)
    } else {
      setUploadSizEexceeded(false)
    }
  }, [JSON.stringify(files)])

  useEffect(() => {
    return () => files.forEach(file => URL.revokeObjectURL(file.preview));
  }, []);

  const locale = {
    'accepted': intl.messages['common.form.error.accepted'],
    'alpha': intl.messages['common.form.error.alpha'],
    'alpha_space': intl.messages['common.form.error.alpha_space'],
    'boolean': intl.messages['common.form.error.boolean'],
    'email': intl.messages['common.form.error.email']?.replace('email', globalConfig?.field_label_email || 'email'),
    'numeric': intl.messages['common.form.error.numeric'],
    'phone': intl.messages['common.form.error.phone'],
    'regex': intl.messages['common.form.error.regex'],
    'required': intl.messages['common.form.error.required'],
    'string': intl.messages['common.form.error.string'],
    'typeof': intl.messages['common.form.error.typeof'],
  };

  SimpleReactValidator.addLocale(langCode, locale);

  const validator = useRef(new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate }, locale: langCode }));
  const handleSubmitForm = (token) => {
    console.log(token);
    if (token?.length > 0) {
      if (validator.current.allValid()) {
        if (uploadSizEexceeded || filesRejected.length > 0) {
          document.querySelectorAll('.error')[0]?.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
          return false;
        }
        setIsError(false);
        setSendingData(true);

        axios.get(webFormSessionUrl).then(result => {
          console.log(result)
          if (result.status === 200) {
            const csrfToken = result.data;
            const formData = new FormData();
            const uploadPromises = files.map((file, index) => {
              formData.append(`file-${index}`, file);
              console.log(formData);
              return axios.post(webFormSubmitFileUrl, file, {
                headers: {
                  'Content-Type': 'application/octet-stream',
                  'Content-Disposition': 'file; filename="' + file.name + '"',
                  'X-CSRF-Token': csrfToken,
                },
                body: formData,
              });
            });

            Promise.all(uploadPromises)
              .then((fileResponses) => {
                console.log(fileResponses)
                const pieceJointeValues = fileResponses.map((response) => response.data.fid[0].value);
                const requestData = {
                  webform_id: formName,
                  surname: lastname,
                  name: firstname,
                  message: message,
                  email: email,
                  subject: subject,
                  piece_jointe: pieceJointeValues,
                };

                fetch(webFormSubmitUrl, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': csrfToken,
                  },
                  body: JSON.stringify(requestData),
                })
                  .then((response) => response.json())
                  .then(
                    (data) => {
                      // On success, do whatever you want
                      setSendingData(false);
                      console.log('success', data);
                      setIsEmailSent(true);
                      window.scrollTo({
                        top: document.querySelector('.section_contact_form').offsetTop - 100,
                        behavior: 'smooth',
                      });
                    },
                    (error) => {
                      console.log('error', error);
                      setSendingData(false);
                      setTimeout(() => { document.querySelectorAll('.error')[0]?.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" }); }, 200)
                    },
                  );
              })
              .catch((error) => {
                console.log('Error uploading files:', error);
                setSendingData(false);
                setTimeout(() => { document.querySelectorAll('.error')[0]?.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" }); }, 200)
              });

            // fetch(webFormSubmitUrl, {
            //   method: 'POST',
            //   headers: {
            //     'Content-Type': 'application/json',
            //     'X-CSRF-Token': csrfToken
            //   },
            //   body: JSON.stringify({
            //     'webform_id': formName,
            //     'surname': lastname,
            //     'name': firstname,
            //     'message': message,
            //     'email': email,
            //     'subject': subject
            //   }),
            // })
            //   .then(response => response.json())
            //   .then(
            //     (data) => {
            //       //on success you do whatever you will want
            //       //console.log('success', data);
            //       setIsEmailSent(true);
            //       window.scrollTo({ top: document.querySelector('.section_contact_form').offsetTop - 100, behavior: 'smooth' });
            //     },
            //     (error) => {
            //       console.log('error', error);
            //     }
            //   );
          }
        });
      } else {
        setSendingData(false);
        validator.current.showMessages();
        setIsError(true);
        setSubmitted(false);
        setTimeout(() => { document.querySelectorAll('.error')[0]?.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" }); }, 200)
        document.querySelectorAll('form .error_exist')[0]?.querySelector('input')?.focus()
      }
    } else {
      console.log(token, 'no token ?');
      setSubmitted(false);
      alert('Error : Token of recaptcha is not valid, please try again');
    }
  };

  return (
    <div className="contact_form_container">
      {isError &&
        <FormattedMessage id="common.form.required">{x => <span className="red">* {x}</span>}</FormattedMessage>
      }
      {
        isEmailSent &&
        <div className='email-sent'>
          <h3 aria-live="polite">
            {/* <FormattedMessage id="common.form.email_sent" /> */}
            {intl.formatMessage({ id: 'common.form.email_sent' })?.replace('Email', globalConfig?.field_label_email || 'Email')}
          </h3>


        </div>
      }
      {!isEmailSent && ( //onSubmit={handleSubmitForm}
        <form autoComplete="on">
          <Input
            validator={validator.current}
            field={{
              id: 'subject',
              filed_type: 'input',
              type: 'text',
              placeholder: intl.formatMessage({ id: 'contact.form.field.subject.placeholder' })?.replace('e-mail', globalConfig?.field_label_email || 'e-mail'),
              name: 'subject',
              label: intl.formatMessage({ id: 'contact.form.field.subject.label' })?.replace('e-mail', globalConfig?.field_label_email || 'e-mail'),
              value: subject,
              textClear: intl.formatMessage({ id: 'contact.form.field.clear_field' })
            }}
            reset={() => setSubject('')}
            change={(value) => setSubject(value)}
          />
          <Input
            validator={validator.current}
            field={{
              id: 'lastname',
              filed_type: 'input',
              type: 'text',
              placeholder: intl.formatMessage({ id: 'contact.form.field.lastname.placeholder' }),
              name: 'lastname',
              label: intl.formatMessage({ id: 'contact.form.field.lastname.label' }),
              value: lastname,
              textClear: intl.formatMessage({ id: 'contact.form.field.clear_field' })
            }}
            reset={() => setLastname('')}
            change={(value) => setLastname(value)}
            autocomplete="family-name"
          />
          <Input
            validator={validator.current}
            field={{
              id: 'firstname',
              filed_type: 'input',
              type: 'text',
              placeholder: intl.formatMessage({ id: 'contact.form.field.firstname.placeholder' }),
              name: 'firstname',
              label: intl.formatMessage({ id: 'contact.form.field.firstname.label' }),
              value: firstname,
              textClear: intl.formatMessage({ id: 'contact.form.field.clear_field' })
            }}
            reset={() => setFirstname('')}
            change={(value) => setFirstname(value)}
            autocomplete="given-name"
          />
          <Input
            validator={validator.current}
            field={{
              id: 'email',
              filed_type: 'input',
              type: 'email',
              placeholder: intl.formatMessage({ id: 'contact.form.field.email.placeholder' })?.replace(/Email|e-mail|email/gi, globalConfig?.field_label_email || 'e-mail'),
              name: 'email',
              label: intl.formatMessage({ id: 'contact.form.field.email.label' })?.replace(/Email|e-mail|email/gi, globalConfig?.field_label_email || 'e-mail'),
              value: email,
              textClear: intl.formatMessage({ id: 'contact.form.field.clear_field' })
            }}
            reset={() => setEmail('')}
            change={(value) => setEmail(value)}
            autocomplete="email"
          />
          <Textarea
            validator={validator.current}
            field={{
              id: 'message',
              filed_type: 'textarea',
              type: 'textarea',
              placeholder: intl.formatMessage({ id: 'contact.form.field.message.placeholder' }),
              name: 'message',
              label: intl.formatMessage({ id: 'contact.form.field.message.label' }),
              value: message,
              textClear: intl.formatMessage({ id: 'contact.form.field.clear_field' })
            }}
            reset={() => { setMessage('') }}
            change={(value) => { setMessage(value) }}
          />
          {
            formName && formName == 'contact_rh' &&
            <div className="input">
              <label>{intl.formatMessage({ id: 'contact.form.field.upload.label' })}</label>
              <div className="filedrop_container">
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  {
                    isDragActive ?
                      <p>{intl.formatMessage({ id: 'contact.form.field.upload.drop_your_file' })}</p> :
                      <>
                        <p className="line_1">{intl.formatMessage({ id: 'contact.form.field.upload.drop_or_select' })}</p>
                        <p className="line_2">{intl.formatMessage({ id: 'contact.form.field.upload.accepted_files' })}</p>
                      </>
                  }
                </div>
              </div>
              {
                files?.length > 0 && (
                  <div className="uploaded_files_listed">
                    <h5 className={uploadSizEexceeded ? 'error' : ''}>{intl.formatMessage({ id: 'contact.form.field.upload.title_files' })}</h5>
                    {
                      uploadSizEexceeded && <p className='error'>{intl.formatMessage({ id: 'contact.form.field.upload.upload_size_eexceeded' })}</p>
                    }
                    <ul>
                      {
                        files.map((file, index) => (
                          <li key={'index-' + index}>
                            {file.name} - {parseFloat(file.size / 1024 / 1024, 10).toFixed(2)} MB <span className="delete_icon" onClick={() => deleteUploadedFile(index)}>X</span>
                          </li>
                        ))
                      }
                    </ul>
                  </div>
                )
              }
              {
                filesRejected?.length > 0 && (
                  <div className="uploaded_files_listed rejected">
                    <h5>{intl.formatMessage({ id: 'contact.form.field.upload.title_files_rejected' })}</h5>
                    <ul>
                      {
                        filesRejected.map((rejectedfile, index) => (
                          <li key={'index-' + index} className="error">
                            {rejectedfile.file.name} - {parseFloat(rejectedfile.file.size / 1024 / 1024, 10).toFixed(2)} MB <span className="delete_icon" onClick={() => deleteUploadedRejectedFile(index)}>X</span><br />
                            <b>{rejectedfile.errors[0]?.message}</b>
                          </li>
                        ))
                      }
                    </ul>
                  </div>
                )
              }
            </div>
          }

          {/* <Checkbox
            validator={validator.current}
            field={{
              id: 'accept_checkbox',
              filed_type: 'checkbox',
              type: 'checkbox',
              name: '?????',//ReturnTranslation('form-accept', langCode),
              label: 'C\'est quoi ce champ ?????', //ReturnTranslation('form-accept', langCode) + '*',
              value: acceptCondition
            }}
            change={(id, value) => setAcceptCondition(value)}
          /> */}
          <FormattedMessage
            id="common.form.msg_body">{x => <p className="legal_test" dangerouslySetInnerHTML={{ __html: x }}></p>}
          </FormattedMessage>
          <div className="btn_container">
            <ReCaptcha
              action="/"
              size="invisible"
              badge="bottomright"
              siteKey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
              onVerify={(token) => handleSubmitForm(token)}
              submitted={submitted}
            />

            {/* <Button
              id="submit"
              primary={true}
              label={intl.formatMessage({ id: 'contact.cta.submit' })}
              onClick={(e) => { e.preventDefault(); setSubmitted(true) }}
            //onClick={handleSubmitForm}
            /> */}
            <div className="btn_container">
              <Button
                id="submit"
                primary={true}
                label={intl.formatMessage({ id: 'contact.cta.submit' })}
                onClick={(e) => { e.preventDefault(); setSubmitted(true) }}
                disabled={sendingData}
              />
              {
                sendingData &&
                <LoaderIcon className="spin_loader" />
              }
            </div>
          </div>
        </form>
      )}
      <span role="status" className="sr-only">
        {
          isEmailSent ?
            <FormattedMessage id="common.form.email_sent" />
            :
            <FormattedMessage id="common.form.email_not_sent" />
        }
      </span>
    </div>
  );
};

export default ContactForm;
