import React, { useEffect, useState } from 'react';

import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import {
  HorizontalYellowLineShape2,
  IconBTAlert,
  IconBTDocument,
  RHIcon,
} from '../../../Atoms/Icons/Icons';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import ContactForm from '../../../components/ContactForm/ContactForm';
import PageBanner from '../../../components/PageBanner/PageBanner';
import Seo from '../../../components/Seo/Seo';
import useMedias from '../../../hooks/useMedias';
import Layout from '../../../Layout';
import AlertBlock from '../../../Molecules/AlertBlock/AlertBlock';
import GraphRH from '../../../Molecules/GraphRH/GraphRH';
import { TitleContact } from '../../../Molecules/TitleContact/TitleContact';
import { TitlePage } from '../../../Molecules/TitlePage/TitlePage';

import './presse.scss';

const classNames = require('classnames');

const ContactTemplate = ({ data, pageContext }) => {
  const { getImage } = useMedias();

  const locale = pageContext.locale;
  const formData = data.forms.edges[0].node;

  const configurationData = data.configurationData.edges[0].node
  const imagesArray = data.allImages.edges;
  const blocData = formData.relationships.field_blocs;
  const [contactFormName, setContactFormName] = useState('');
  const contact_webform_id = formData.webform.drupal_internal__target_id;
  const ourContact = data.ourContact.edges[0].node;
  const metaTags = formData.metatag;
  let metaTitle = '';
  let metaDesc = '';

  metaTags.forEach((meta) => {
    if (meta.attributes.name === 'title') {
      metaTitle = meta.attributes.content;
    }
    if (meta.attributes.name === 'description') {
      metaDesc = meta.attributes.content;
    }
  });

  useEffect(() => {
    setContactFormName(contact_webform_id);
  }, []);

  const titleH4 = function (myText) {
    if (myText.indexOf('<p>') > -1) {
      return myText.substring(
        myText.lastIndexOf('<p>') + 4,
        myText.lastIndexOf('</p>')
      );
    }
    return myText.substring(
      myText.lastIndexOf('<h4>') + 4,
      myText.lastIndexOf('</h4>')
    );
  };

  const bodyText = function (myText) {
    return myText.split('<h4>' + titleH4(myText) + '</h4>')[1];
  };

  return (
    <Layout imagesArray={imagesArray}>
      <Seo
        title={formData?.field_metatag?.title ? formData?.field_metatag?.title : metaTitle}
        description={formData?.field_metatag?.description ? formData?.field_metatag?.description : metaDesc}
      />
      <div className={classNames('page_template', 'page_contact_form')}>
        <PageBanner
          visuel={getImage(
            imagesArray,
            formData.relationships?.field_image?.drupal_internal__mid
          )}
          alt={''}
          scroll={false}
        >
          <div className="wrapper_page">
            <Breadcrumb
              page_banner={true}
              data={{
                // grandParentPage: { title: 'Acceuil', url: '/' },
                parentPage: { title: 'Contact', url: ourContact.link?.url },
                currentPage: { title: formData.title, url: '' },
                locale,
              }}
            />
            <TitlePage color="color_white" title={formData.title} />
          </div>
        </PageBanner>
        <section
          className={classNames('section_content', 'section_contact_form')}
        >
          <div className="wrapper_page">
            <TitleContact title={formData.field_form_title}></TitleContact>
            <div className="col">
              <div className="contact_form_component_container">
                <ContactForm
                  formName={contactFormName}
                  langCode={locale}
                  globalConfig={configurationData}
                />
              </div>

              <div className="contact-card">
                {formData.body?.processed && (
                  <div className="body_text">
                    <div
                      className="desc"
                      dangerouslySetInnerHTML={{
                        __html: formData.body?.processed,
                      }}
                    ></div>
                  </div>
                )}
                {blocData.map((item, i) => (
                  <div className="alert_div" key={i}>
                    <p
                      dangerouslySetInnerHTML={{ __html: item.body?.processed }}
                      style={{ display: 'none' }}
                    ></p>
                    {item.field_link && (
                      <AlertBlock
                        key={i}
                        link={item.field_link.url}
                        label={item.body.processed}
                        icon={
                          item.field_type_bloc_contact == 'alert' ? (
                            <IconBTAlert />
                          ) : item.field_type_bloc_contact == 'rh' ? (
                            <RHIcon />
                          ) : (
                            <IconBTDocument />
                          )
                        }
                      />
                    )}

                    {item.field_chiffre_offre && (
                      <GraphRH
                        jobs={item.field_chiffre_offre}
                        text={item.field_offre_text}
                        textColor="color_black"
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ContactTemplateQuery(
    $locale: String!
    $slug: String!
    $node_internal_id: Int!
    $imagesID: [Int!]
  ) {
    forms: allNodeWebform(
      filter: {
        langcode: { eq: $locale }
        path: { alias: { eq: $slug } }
        drupal_internal__nid: { eq: $node_internal_id }

      }
    ) {
      edges {
        node {
          body {
            processed
          }
          langcode
          path {
            alias
          }
          field_metatag {
            description
            title
          }
          metatag {
            attributes {
              content
              name
            }
          }
          webform {
             drupal_internal__target_id
          }
          drupal_internal__nid
          title
          field_form_title
          relationships {
            field_blocs {
              __typename
              ... on Node {
                ... on block_content__block_contact {
                  body {
                    processed
                  }
                  field_link {
                    url
                    title
                  }
                  field_type_bloc_contact
                }
                ... on block_content__block_emploi {
                  field_offre_text
                  field_chiffre_offre
                }
              }
            }
            field_image {
              drupal_internal__mid
              field_media_image{
                alt
              }
            }
          }
        }
      }
    }
    allImages: allMediaImage(
      filter: {
        langcode: { eq: "fr" }
        drupal_internal__mid: { in: $imagesID }
      }
    ) {
      edges {
        node {
          drupal_internal__mid
          relationships {
            field_media_image {
              uri {
                url
              }
              image_style_uri {
                gatsby_thumbnail
                gatsby_medium
                gatsby_large
                gatsby_wide
                gatsby_medium_carousel
                gatsby_thumbnail_carousel
                gatsby_innov_carousel
                gatsby_activites_carousel
                gatsby_profile_large
                gatsby_profile_thumbnail
                webp
              }
            }
          }
          langcode
        }
      }
    }
    configurationData: allBlockContentBlockConfiguration(
      filter: {
        langcode: { eq: $locale }
      }
    ) {
      edges{
        node {
          langcode
          info
          field_copyright
          field_label_email
          field_lang_and_countrycode
          field_image {
            drupal_internal__target_id
          }
          field_image_2 {
            drupal_internal__target_id
          }
          field_image_footer{
            drupal_internal__target_id
          }
          relationships {
            field_social_media_share {
              drupal_internal__tid
            }
          }
        }
      }
    }
    ourContact: allMenuLinkContentMenuLinkContent(
      filter: {
        langcode: { eq: $locale }
        menu_name: { eq: "footer-nous-contacter" }
        enabled: { eq: false }
      }
      sort: {
        fields: weight
      }
    ) {
      edges {
        node {
          drupal_id
          langcode
          link {
            url
          }
          menu_name
          title
        }
      }
    }
  }
`;

ContactTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default ContactTemplate;
